.avatar {
   width: 28.327px;
   height: 28.327px;
   flex-shrink: 0;
   background-color: #a2efed;
   display: flex;
   align-items: center;
   color: #035970;
   text-align: center;
   font-size: 18px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 1.8px;
}

.username {
   display: flex;
   gap: 12px;
   align-items: center;
   border-radius: 8px;
   border: 1.5px solid #c1c1c1;
   padding: 4px 12px;
   /* width: 180px; */
   height: 44px;
   justify-content: space-between;
}

.bell {
   display: flex;
   width: 45px;
   height: 44px;
   padding: 10px;
   justify-content: center;
   align-items: center;
   gap: 10px;
   border-radius: 8px;
   border: 1.5px solid #c1c1c1;
   cursor: pointer;
}

.roleParagraph {
   color: #777;
   font-size: 12px;
   font-weight: 500;
   margin: 0;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}

.fullnameParagraph {
   margin: 0;
   color: #3a3737;
   font-size: 14px;
   font-weight: 600;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation1.MuiPaper-rounded {
   /* Your CSS properties here */
   width: 400px;
}

.check-disabled {
   background-color: unset;
}
