@media (max-width: 768px) {
   .boxStyle {
      padding: 20px;
      margin: 20px 0 40px 0;
   }
   .fundsContainer {
      padding: 20px;
   }
   .imageContainer {
      // width: 100%;
      padding: 20px;
   }
   .socialChangeContainer {
      padding: 20px;
   }
   .mainContainer {
      padding: 20px;
   }
   .optimizingContainer-turkey {
      padding: 20px;
      margin-top: 40px;
   }
   .gridContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
   }
   .partnersContainer {
      gap: 20px;
      margin-bottom: 20px;
   }
   .platformContainer-turkey {
      padding: 20px;
      margin-bottom: 60px;
   }
   .platformContainer {
      padding: 20px;
      margin-bottom: 60px;
   }
   .imageAbsolute-turkey {
      position: unset;
   }
   .fontSection {
      font-size: 18px;
   }
}

@media (min-width: 768px) {
   .boxStyle {
      margin: 120px 0 120px 0;
   }
   .fundsContainer {
      padding: 20px;
   }
   .imageContainer {
      width: 40%;
      padding: 20px 100px 20px 0;
   }
   .socialChangeContainer {
      width: 40%;
   }
   .mainContainer {
      padding: 100px;
   }
   .optimizingContainer-turkey {
      padding: 100px;
   }
   .gridContainer {
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 32px;
   }
   .partnersContainer {
      gap: 24px;
   }
   .platformContainer {
      padding: 100px;
      margin-bottom: 200px;
   }
   .platformContainer-kosovo {
      padding: 100px 0;
      margin-bottom: 200px;
   }
   .fontSection {
      font-size: 32px;
   }
}

@media (min-width: 1440px) {
   .imageAbsolute-turkey {
      position: absolute;
      top: 0;
      right: 0;
      // padding: 0 100px 0 20px;
      width: auto;
      height: calc(100% + 100px);

      img {
         height: calc(100% + 100px);
         object-fit: cover;
      }
   }
}

.imageAbsolute-turkey img {
   border-radius: 16px;
}
