.headerLogo-turkey {
   display: flex;
   min-height: 96px;
   height: 96px;
   padding: 0 15px;
   align-items: center;
   gap: 288px;
   background: var(--Neutrals-White, #fff);

   .logo {
      display: flex;
      gap: 20px;

      .vertical-line {
         width: 1px;
         height: 40px;
         background-color: #d9d9d9;
      }
   }

   /* Media Query for Devices Smaller than 400px */
   @media (max-width: 400px) {
      padding: 0; /* Remove all padding */
      gap: 20px; /* Adjust gap for smaller screens */

      .logo {
         gap: 10px; /* Reduce gap between logos */
      }
   }
}

.hero-bg-turkey {
   min-height: 800px;
   position: relative;
   display: flex;
   align-items: center;

   @media (max-width: 991px) {
      min-height: auto;
   }

   .img-bg {
      position: absolute;
      max-height: auto;
      width: 100%;
      height: 100%;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }

      .banner-overlay {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background: radial-gradient(
            46.12% 18.81% at 56.28% 58.87%,
            rgba(0, 61, 170, 0.34) 0%,
            rgba(0, 38, 106, 0.59) 100%
         );
         z-index: 1;

         background-blend-mode: multiply;
      }
   }

   .container {
      height: 100%;
   }

   .hero-banner-content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      align-items: center;

      .hero-text {
         flex: 0 0 50%;
         display: flex;
         justify-content: center;
         align-items: flex-start;
         flex-direction: column;
         gap: 20px;

         h4 {
            color: var(--Neutrals-White, #fff);
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 45px; /* 150% */
            max-width: 500px;
         }

         p {
            color: #fff;
            // font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            max-width: 500px;
         }

         button {
            border-style: none;
            background: transparent;
            color: var(--Neutrals-White, #fff);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            display: flex;
            gap: 6px;
            transition: all 0.3s ease;
            max-width: 500px;
            justify-content: flex-start !important;
            width: 100%;

            img {
               width: 15px;
               margin-top: 5px;
            }
         }
      }

      .hero-form {
         flex: 0 0 50%;
         display: flex;
         // justify-content: center;

         #kt_login_forgot-blue {
            color: #5492ff !important;
            margin: 0 10px 0 0 !important;
         }

         .sign-btn {
            background-color: #4158cf !important;
            color: #fff;
         }

         #kt_login_signup {
            color: #5492ff !important;
         }

         .kt_login_signin_submit.loading {
            background-color: #fff !important;
         }
      }

      @media (max-width: 767px) {
         .custom-div {
            width: 100%;
         }
         .lottie-div {
            height: auto;
         }
         .lottie-div-registration {
            height: 100%;
         }
      }

      @media (min-width: 768px) {
         .custom-div {
            max-width: 510px;
            width: 100%;
            border-radius: 8px;
            box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
         }
      }

      @media (max-width: 991px) {
         min-height: auto;
         gap: 20px;
         .hero-text {
            flex: 0 0 100%;
            align-items: center;
         }

         .hero-form {
            flex: 0 0 100%;
         }
      }

      /* Media Query for Devices Smaller than 768px */
      @media (max-width: 768px) {
         h4,
         p {
            width: 100%; /* Set width to 100% for smaller devices */
            padding: 0px; /* Reduce padding */
         }

         button {
            left: 20px; /* Adjust button position */
         }
      }
   }
}

@media (max-width: 540px) {
   .hero-text {
      padding: 16px;
   }
}

.password-section {
   display: flex;

   .eyeButton {
      cursor: pointer;
      margin-left: -25px;
      // margin-top: 5px;
   }
}
@media (max-width: 768px) {
   // .hero-banner-content {
   //   padding: 16px;
   // }
   .spanStyle {
      font-size: 25px;
   }
   .textContent {
      display: none;
   }
}

@media (min-width: 768px) {
   // .hero-banner-content {
   //   padding: 100px;
   // }
   .spanStyle {
      font-size: 48px;
   }
   .textContent {
      display: block;
   }
}

.turkey-principles-component {
   // background: url("../assets/images/principles.jpg") no-repeat;
   background-color: #dfe6ff;
   background-size: cover;
   padding: 60px 0;
   width: 100%;
   margin-bottom: 140px;

   .principles-title {
      display: none;
      color: #003daa;
      font-size: 22px;
      line-height: 32px;
      padding: 40px 0 20px;
   }

   .container {
      background: rgba(255, 255, 255, 0);
      color: #333;

      .row {
         .col-md-6 {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
               position: absolute;
               top: 0;
               height: calc(100% + 140px);
               max-width: 100%;
               object-fit: cover;
               // padding: 20px;
               border-radius: 16px;
            }
         }

         .text-content {
            padding: 20px;
            color: #3a3737;

            h2 {
               font-size: 24px;
               line-height: 36px;
               color: #3a3737;
               font-weight: 600;
            }

            ul {
               color: #3a3737;
               // list-style-type: none;
               padding-left: 25px;

               li {
                  font-size: 16px;
                  line-height: 26px;
                  font-weight: 400;
               }
            }
         }
      }
   }
}

// Media query for smaller devices
@media (max-width: 767.98px) {
   .principles-component {
      margin-bottom: 40px;

      .principles-title {
         display: block;
         padding: 15px;
         font-weight: 600;
      }

      .container {
         .row {
            flex-direction: column;

            .col-md-6 {
               text-align: center;
               padding: 0;

               img {
                  position: relative !important;
                  width: 100%;
                  margin: 0 auto;
                  padding: 20px;
               }
            }

            .text-content {
               padding: 20px 0;

               h2 {
                  display: none;
               }

               ul {
                  text-align: left;
                  padding: 0 40px;
                  li {
                     font-size: 18px;
                     line-height: 26px;
                  }
               }
            }
         }
      }
   }
}
// prej qitu
.mainContainer-afghanistan {
   // margin-bottom: 40px;
   gap: 20px;
   // height: 100vh; /* or any other height */
   // overflow: hidden;
}

.mainContainer-afghanistan .mainContainer-wrapper {
   display: flex;
   justify-content: flex-start;
   position: relative;
   padding: 0;
   // gap: 50px;
   align-items: center;
}

.mainContainer-afghanistan .mainContainer-wrapper .main-turkey-image {
   flex: 0 0 50%;
}

.mainContainer-afghanistan .mainContainer-wrapper .main-image-wrapper-2 {
   flex: 0 0 450px;
}

@media (max-width: 768px) {
   .mainContainer-wrapper {
      flex-direction: column;
      // padding: 50px 0;
   }

   .btn-turkey {
      margin-bottom: 20px;
   }
   .section {
      padding: 20px 0;
   }
   .containerWrapper {
      gap: 40px;
   }
}
@media (min-width: 768px) {
   .mainContainer-afghanistan {
      padding: 100px 100px 100px;
      // margin-bottom: 50px;
   }
   .text-container-Turkey {
      flex: 1 1 60%; /* Adjust the percentage as needed */
      max-width: 60%;
   }

   .section {
      padding: 100px;
   }
   .containerWrapper {
      gap: 80px;
   }
}

@media (min-width: 1024px) {
   .mainContainer-afghanistan {
      gap: 93px;
   }
}
@media screen and (max-width: 768px) {
   .mainContainer-afghanistan {
      margin: 40px 0;
   }
}

.section {
   align-items: center;
   background-color: rgba(223, 230, 255, 1);
   display: flex;
   gap: 90px;
   position: relative;
}

.section .text-content {
   align-items: flex-start;
   display: flex;
   flex-direction: column;
   gap: 16px;
   position: relative;
}

.section .partner-with-kaporg {
   align-self: stretch;
   color: rgba(0, 61, 170, 1);
   font-size: 32px;
   font-weight: 600;
   // margin-top: -1px;
   position: relative;
}

.section .text-wrapper {
   font-size: 16px;
   font-weight: 700;
   letter-spacing: 0.32px;
}

.section .span {
   font-size: 16px;
   letter-spacing: 0.32px;
}

.principles-component-afghanistan {
   // background: url("../assets/images/principles.jpg") no-repeat;
   background-color: #dfe6ff;
   background-size: cover;
   padding: 60px 0;
   width: 100%;
   margin-bottom: 140px;

   .principles-title {
      display: none;
      color: #003daa;
      font-size: 22px;
      line-height: 32px;
      padding: 40px 0 20px;
   }

   .container {
      background: rgba(255, 255, 255, 0);
      color: #333;

      .row {
         .col-md-6 {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
               position: absolute;
               top: 0;
               height: calc(100% + 140px);
               max-width: 100%;
               object-fit: cover;
               // padding: 20px;
               border-radius: 16px;
            }
         }

         .text-content {
            padding: 20px;
            color: #3a3737;

            h2 {
               font-size: 24px;
               line-height: 36px;
               color: #3a3737;
               font-weight: 600;
            }

            ul {
               color: #3a3737;
               // list-style-type: none;
               padding-left: 25px;

               li {
                  font-size: 16px;
                  line-height: 26px;
                  font-weight: 400;
               }
            }
         }
      }
   }
}

// Media query for smaller devices
@media (max-width: 767.98px) {
   .principles-component-afghanistan {
      margin-bottom: 40px;

      .principles-title {
         display: block;
         padding: 15px;
         font-weight: 600;
      }

      .container {
         .row {
            flex-direction: column;

            .col-md-6 {
               text-align: center;
               padding: 0;

               img {
                  position: relative !important;
                  width: 100%;
                  margin: 0 auto;
                  padding: 20px;
               }
            }

            .text-content {
               padding: 20px;

               h2 {
                  display: none;
               }

               ul {
                  text-align: left;
                  padding: 0 40px;
                  li {
                     font-size: 18px;
                     line-height: 26px;
                  }
               }
            }
         }
      }
   }
}
