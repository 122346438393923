// @font-face {
//   font-family: 'SoehneBuch';
//   font-style: normal;
//   font-weight: normal;
//   src: url('https://frontend-fonts.s3.amazonaws.com/Fonts/soehne-buch.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'SoehneBuch';
//   font-style: normal;
//   font-weight: bold;
//   src: url('https://frontend-fonts.s3.amazonaws.com/Fonts/soehne-kraftig.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'SoehneBuch';
//   font-style: normal;
//   font-weight: lighter;
//   src: url('https://frontend-fonts.s3.amazonaws.com/Fonts/soehne-leicht.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'SoehneMono';
//   font-style: normal;
//   font-weight: normal;
//   src: url('https://frontend-fonts.s3.amazonaws.com/Fonts/soehne-mono-buch.woff2') format('woff2');
// }

* {
   .loginForAfrica {
      font-family: "SoehneBuch";
      font-weight: normal;
      background-color: #f3efea;
   }
}
