* {
   scroll-behavior: smooth;
}

p.no-data {
   margin: 15px 5px;
   color: var(--kaporg-black-color);
}

.disabled {
   pointer-events: none;
   opacity: 0.5;
}

.clickable {
   cursor: pointer;
}

.wide {
   .modal-dialog {
      max-width: 100%;
      @media (min-width: 1300px) {
         max-width: 1100px;
      }
   }
}

.dark-mode {
   .dropzone-wrapper {
      background-color: var(--kaporg-dark-green);
   }
   .MuiTabs-flexContainer {
      background-color: var(--kaporg-dark-black);
      color: var(--kaporg-black-text);
   }

   .skeleton-loader rect {
      fill: var(--kaporg-dark-black) !important;
   }

   .list-group-item {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-gray-3);
   }

   .form-group input,
   .form-group span {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-black-text);
   }
   .css-1rhbuit-multiValue {
      background-color: var(--kaporg-input-border-background);
   }
   .css-1uccc91-singleValue {
      color: var(--kaporg-black-text);
   }

   .react-datepicker-wrapper input {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-white-color) !important;
   }
   .submit-textarea {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-white-color);
   }
   .MuiMenu-list {
      background-color: var(--kaporg-popup-background) !important;
      color: var(--kaporg-dark-green) !important;
   }
   .css-yk16xz-control {
      background-color: var(--kaporg-black) !important;
   }
   .css-1pahdxg-control {
      background-color: var(--kaporg-black) !important;
   }
   .no-data-message {
      color: var(--kaporg-black-text);
   }
   .css-26l3qy-menu {
      background-color: var(--kaporg-black-color);
   }
   .form-group > div:first-of-type > div:first-of-type {
      background-color: var(--kaporg-input-color) !important;
   }
   .MuiTypography-colorTextSecondary {
      color: white;
   }
   .MuiFormControl-root {
      background-color: var(--kaporg-dark-green) !important;
   }
   .MuiPaper-root {
      background-color: var(--kaporg-dark-green);
      color: white;
   }

   .form-group > div > div > div > div {
      color: white !important;
   }
}

.ant-dropdown .ant-dropdown-menu {
  max-height: 290px;
  overflow: auto;
  column-count: 2;
  -webkit-columns: 2;
  
}
