//
// Aside
//

// General Mode(all devices)
.aside {
   box-shadow: rgba(17, 17, 26, 0.1) 1px 0px 1px;
   background-color: #4158cf;
   transition: get($aside-config, base, transition);

   .menu-item {
      margin: 4px !important;
   }

   .svg-icon i.green::before {
      color: var(--kaporg-dark-green) !important;
   }

   .aside-menu {
      background-color: $kaporg-white;
   }

   .aside-menu-left {
      background-color: #4158cf;
   }

   .svg-icon i::before {
      color: $kaporg-white;
   }

   .btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text),
   .btn.btn-clean:not(:disabled):not(.disabled).active,
   .show>.btn.btn-clean.dropdown-toggle,
   .show .btn.btn-clean.btn-dropdown {
      background-color: rgba(200, 200, 200, 0.41);
   }

   .btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
   .btn.btn-clean:focus:not(.btn-text),
   .btn.btn-clean.focus:not(.btn-text) {
      background-color: rgba(200, 200, 200, 0.41);
   }

   .menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-arrow {
      color: #ffffff !important;
   }

   .aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
      background-color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span {
      background-color: rgba(159, 159, 159, 1) !important;
   }

   // Aside Secondary Panel enabled
   .aside-secondary-enabled & {
      width: get($aside-config, base, primary-width) + get($aside-config, base, secondary-width);
   }

   // Aside Secondary Panel disabled
   .aside-secondary-disabled & {
      width: get($aside-config, base, primary-width);
   }

   // Aside Primary Panel
   .aside-primary {
      // width: get($aside-config, base, primary-width);
      width: 207px;
      flex-shrink: 0;

      // Aside Secondary Panel disabled
      .aside-secondary-enabled & {
         border-right: 1px solid $kaporg-dark-green;
      }
   }

   // Aside Secondary Panel
   .aside-secondary {
      overflow: hidden;
      background-color: $kaporg-white;

      // Workspace
      .aside-workspace {
         width: 100%;
         flex-shrink: 0;
      }

      // Menu
      .aside-menu {
         @include perfect-scrollbar-ver-offset(4px);
      }
   }

   .organization-management-class {
      display: none !important;
   }
}

.workspace-aside {
   .aside-brand .brand-logo img {
      padding: 1px;
   }

   .btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
   .btn.btn-hover-primary:focus:not(.btn-text),
   .btn.btn-hover-primary.focus:not(.btn-text) {
      background-color: $kaporg-green-lighter !important;
      border-color: $kaporg-green-lighter !important;
   }

   .aside-toggle i,
   .aside .aside-toggle.aside-toggle-active i {
      background-color: unset !important;
   }

   .menu-nav>.menu-item>.menu-link .menu-text {
      color: rgba(159, 159, 159, 1) !important;
   }

   .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-text {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-text {
      color: $kaporg-dark-green !important;
      font-weight: 500 !important;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }

   .menu-item.menu-item-active>.menu-link .menu-text {
      color: $kaporg-dark-green !important;
      font-weight: 500 !important;
   }

   .aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
      fill: $kaporg-dark-green;
   }

   .aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
      fill: $kaporg-dark-green;
   }

   .aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
   .aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill] {
      fill: $kaporg-dark-green;
   }

   .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
      background-color: $kaporg-dark-green;
   }
}

.admin-aside {
   .menu-nav {
      max-height: 65vh;
      overflow-y: auto;
   }

   .menu-nav>.menu-item.menu-item-open>.menu-link .menu-text {
      color: rgba(255, 255, 255, 1) !important;
   }

   .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-text {
      color: rgba(255, 255, 255, 1) !important;
   }

   .aside-brand {
      background-color: #4158cf;

      .brand-logo {
         padding-right: 60px;
      }
   }
}

body {
   background-color: #ffffff;
}

// New style for aside

.aside {
   .aside-workspace {
      height: 100%;
      margin: 0 !important;
      border-right: 1px solid #cad6ff;

      .tab-content {
         height: 100%;
         background-color: #dde9ff;

         .tab-pane {
            height: 100%;

            .aside-menu-wrapper {
               height: 100%;
               padding: 0px !important;

               .aside-menu {
                  height: 100%;
                  background-color: #dde9ff;
                  padding: 0px !important;

                  .wrapper {
                     padding-left: 0 !important;

                     .menu-nav {
                        padding: 0;
                     }
                  }
               }
            }
         }
      }
   }

   &.workspace-aside {
      @include media-breakpoint-down(md) {
         &.aside-on {
            left: 0 !important;
         }

         left: -465px !important;
      }
   }
}

.aside-secondary-enabled .aside.workspace-aside {
   width: 465px;
}

.aside-secondary-enabled .aside.no-second-menu {
   width: fit-content;
}

.aside-secondary-enabled .workspace-aside-organization-management {
   width: 206px;
   overflow-y: auto;
   overflow-x: hidden;
}

.aside-secondary-enabled .workspace-aside-organization-management::-webkit-scrollbar {
   width: 8px;
}

.aside-secondary-enabled .workspace-aside-organization-management::-webkit-scrollbar-track {
   background: #f1f1f1;
}

.aside-secondary-enabled .workspace-aside-organization-management::-webkit-scrollbar-thumb {
   background: #d3d3d3;
   border-radius: 4px;
}

.aside-secondary-enabled .workspace-aside-organization-management::-webkit-scrollbar-thumb:hover {
   background: #b0b0b0;
}

.aside-secondary-enabled .workspace-aside-guided-consultant {
   width: 206px;
}

.aside-primary {
   .aside-brand {
      padding: 25px 20px !important;
      align-items: flex-start !important;
   }

   .aside-nav {
      padding-top: 0 !important;

      ul {
         &.no-second-menu {
            padding-right: 20px !important;
         }

         width: 100% !important;
         padding: 0 20px !important;
         padding-right: 30px !important;

         h4 {
            font-size: 14px;
            font-weight: 100;
            color: #c0f9f8;
            padding: 10px 0;
         }

         li {
            position: relative;

            a {
               padding: 8px 15px;
               min-height: 48px;
               display: flex;
               align-items: center;
               border-radius: 8px;
               transition: 0.2s;

               .svg-icon {
                  display: flex;
                  align-items: center;

                  img {
                     max-width: 24px;
                     min-width: 24px;
                     max-height: 24px;
                     width: 24px;
                     flex: auto;
                     margin-right: 6px;
                     margin-left: 6px;
                     filter: brightness(0) invert(1);
                     opacity: 0.7;
                  }

                  span {
                     display: flex;
                     align-items: center;
                     flex: auto;
                     font-size: 14px;
                     line-height: 16px;
                     color: #fff;
                     opacity: 0.7;
                  }
               }

               &:hover {
                  background-color: #fff;

                  .svg-icon {
                     img {
                        filter: none;
                        opacity: 1;
                     }

                     span {
                        color: #4158cf;
                        opacity: 1;
                     }
                  }
               }

               .arrow-menu {
                  display: none;
                  position: absolute;
                  right: -31px;
                  top: 50%;
                  transform: translateY(-50%);
                  pointer-events: none;
               }

               &.active {
                  background-color: #fff;

                  .svg-icon {
                     img {
                        filter: none;
                        opacity: 1;
                     }

                     span {
                        color: #4158cf;
                        opacity: 1;
                     }
                  }

                  .arrow-menu {
                     display: block;
                  }
               }
            }
         }

         .nav-banner {
            padding: 0 !important;

            a {
               padding: 0;
               background-color: #b3bbec3b;
               border: 1px solid #fff;
               transition: 0.2s;

               .nav-link-banner-wrap {
                  display: flex;
                  flex-direction: column;
                  overflow: hidden;
                  border-radius: 8px;

                  span {
                     padding: 10px 10px;
                     color: #fff;
                     opacity: 0.7;
                     font-size: 14px;
                     line-height: 16px;
                     transition: 0.2s;
                     position: relative;
                     z-index: 1;
                  }

                  img {
                     transition: 0.2s;
                  }
               }

               &:hover {
                  .nav-link-banner-wrap {
                     span {
                        opacity: 1;
                        color: #4158cf;
                     }

                     img {
                        transform: scale(1.3) translateY(10px) translateX(20px);
                        opacity: 0.5;
                     }
                  }
               }

               &.active {
                  .nav-link-banner-wrap {
                     span {
                        opacity: 1;
                        color: #4158cf;
                     }

                     img {
                        transform: scale(1.3) translateY(10px) translateX(20px);
                        opacity: 0.5;
                     }
                  }
               }
            }
         }
      }
   }

   .aside-footer {
      width: 100%;
      padding: 0;
      padding-bottom: 15px;

      .nav-footer-item {
         width: 100% !important;
         padding: 0 20px !important;

         a {
            padding: 8px 15px;
            min-height: 48px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            transition: 0.2s;

            .nav-footer-item-wrap {
               display: flex;
               align-items: center;

               img {
                  max-width: 24px;
                  min-width: 24px;
                  max-height: 24px;
                  width: 24px;
                  flex: auto;
                  margin-right: 6px;
                  margin-left: 6px;
                  filter: brightness(0) invert(1);
                  opacity: 0.7;
               }

               span {
                  display: flex;
                  align-items: center;
                  flex: auto;
                  font-size: 14px;
                  line-height: 16px;
                  color: #fff;
                  opacity: 0.7;
               }
            }

            &:hover {
               background-color: #fff;

               .nav-footer-item-wrap {
                  img {
                     filter: none;
                     opacity: 1;
                  }

                  span {
                     color: #4158cf;
                     opacity: 1;
                  }
               }
            }

            ul {
               width: 100% !important;
               padding: 0 20px !important;
               padding-right: 30px !important;

               h4 {
                  font-size: 14px;
                  font-weight: 100;
                  color: #c0f9f8;
                  padding: 10px 0;
               }

               li {
                  position: relative;

                  a {
                     padding: 8px 15px;
                     min-height: 48px;
                     display: flex;
                     align-items: center;
                     border-radius: 8px;
                     transition: 0.2s;

                     .svg-icon {
                        display: flex;
                        align-items: center;

                        img {
                           max-width: 24px;
                           min-width: 24px;
                           max-height: 24px;
                           width: 24px;
                           flex: auto;
                           margin-right: 6px;
                           margin-left: 6px;
                           filter: brightness(0) invert(1);
                           opacity: 0.7;
                        }

                        span {
                           display: flex;
                           align-items: center;
                           flex: auto;
                           font-size: 14px;
                           line-height: 16px;
                           color: #fff;
                           opacity: 0.7;
                        }
                     }

                     &:hover {
                        background-color: #fff;

                        .svg-icon {
                           img {
                              filter: none;
                              opacity: 1;
                           }

                           span {
                              color: #4158cf;
                              opacity: 1;
                           }
                        }
                     }

                     &.active {
                        background-color: #fff;

                        .svg-icon {
                           img {
                              filter: none;
                              opacity: 1;
                           }

                           span {
                              color: #4158cf;
                              opacity: 1;
                           }
                        }
                     }
                  }

                  span {
                     color: #4158cf;
                     opacity: 1;
                  }

                  &.active {
                     .arrow-menu {
                        position: absolute;
                        right: -31px;
                        top: 50%;
                        transform: translateY(-50%);
                     }

                     .organization-management-arrow-menu {
                        display: none;
                     }
                  }
               }

               .arrow-menu {
                  display: none;
                  position: absolute;
                  right: -31px;
                  top: 50%;
                  transform: translateY(-50%);
                  pointer-events: none;
               }

               &.active {
                  background-color: #fff;
               }

               .nav-banner {
                  padding: 0 !important;

                  a {
                     padding: 0;
                     background-color: #b3bbec3b;
                     border: 1px solid #fff;
                     transition: 0.2s;

                     .nav-link-banner-wrap {
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        border-radius: 8px;

                        span {
                           padding: 10px 10px;
                           color: #fff;
                           opacity: 0.7;
                           font-size: 14px;
                           line-height: 16px;
                           transition: 0.2s;
                           position: relative;
                           z-index: 1;
                        }

                        img {
                           transition: 0.2s;
                        }
                     }

                     &:hover {
                        .nav-link-banner-wrap {
                           span {
                              opacity: 1;
                              color: #4158cf;
                           }

                           img {
                              transform: scale(1.3) translateY(10px) translateX(20px);
                              opacity: 0.5;
                           }
                        }
                     }

                     &.active {
                        .nav-link-banner-wrap {
                           span {
                              opacity: 1;
                              color: #4158cf;
                           }

                           img {
                              transform: scale(1.3) translateY(10px) translateX(20px);
                              opacity: 0.5;
                           }
                        }
                     }
                  }

                  .arrow-menu {
                     display: block;
                  }
               }
            }
         }
      }

      .aside-footer {
         width: 100%;
         padding: 0;
         padding-bottom: 15px;

         .nav-footer-item {
            width: 100% !important;
            padding: 0 20px !important;

            a {
               padding: 8px 15px;
               min-height: 48px;
               display: flex;
               align-items: center;
               border-radius: 8px;
               transition: 0.2s;

               .nav-footer-item-wrap {
                  display: flex;
                  align-items: center;

                  img {
                     max-width: 24px;
                     min-width: 24px;
                     max-height: 24px;
                     width: 24px;
                     flex: auto;
                     margin-right: 6px;
                     margin-left: 6px;
                     filter: brightness(0) invert(1);
                     opacity: 0.7;
                  }

                  span {
                     display: flex;
                     align-items: center;
                     flex: auto;
                     font-size: 14px;
                     line-height: 16px;
                     color: #fff;
                     opacity: 0.7;
                  }
               }

               &:hover {
                  background-color: #fff;

                  .nav-footer-item-wrap {
                     img {
                        filter: none;
                        opacity: 1;
                     }

                     span {
                        color: #4158cf;
                        opacity: 1;
                     }
                  }
               }
            }
         }
      }
   }
}

.aside-secondary {
   width: 256px;
}

// END of new style

//Dark mode for brand
.dark-mode {
   .aside {
      background-color: #021c17 !important;

      .aside-menu {
         background-color: #032b26 !important;

         .menu-nav {
            .menu-section {
               .menu-text {
                  color: rgba(240, 240, 240, 0.92);
               }
            }

            .menu-item:active {
               .menu-link {
                  background: rgba(45, 49, 51, 0.36);
               }
            }
         }
      }

      .svg-icon i.green::before {
         color: #bfbfbf !important;
      }

      .menu-item.menu-item-active>.menu-link .menu-text {
         color: var(--kaporg-dark-green) !important;
         font-weight: 500 !important;
      }
   }

   .admin-aside {
      background-color: var(--kaporg-dark-green) !important;

      .aside-brand {
         background-color: var(--kaporg-dark-green) !important;
      }

      .aside-menu-left {
         background-color: var(--kaporg-dark-green) !important;
      }
   }

   .aside-secondary {
      background-color: #032b26;

      .aside-workspace {
         .tab-content {
            background-color: #032b26;
         }
      }
   }

   .content {
      background-color: rgb(2, 22, 18);
   }

   .container-fluid {
      background-color: #021c17;
   }

   .subheader {
      background-color: #011916;
   }

   .footer {
      background-color: #011916;
   }

   .subheader .subheader-search-bar .search-input {
      background-color: #032d25;
   }

   .table {
      color: var(--kaporg-lightest-green);
   }

   .form-group label {
      color: var(--kaporg-black-text);
   }

   .btn.sign-btn {
      background-color: var(--kaporg-lightest-green);
      color: black;
   }

   .form-control.form-control-solid {
      background-color: #0c110f;
   }

   .form-control.form-control-solid:focus {
      background-color: black;
      color: var(--kaporg-black-text);
   }

   .text-dark {
      color: var(--kaporg-white-color) !important;
   }
}

.aside-secondary-enabled .aside-admin {
   width: 242px !important;
}

.aside-third-disabled .aside-admin {
   width: 0px !important;
}

.aside-secondary-enabled.aside-fixed.admin-layout .wrapper {
   padding-left: 0px;
}

.aside-secondary-enabled.aside-fixed .wrapper {
   padding-left: 206px;
}

@media (max-width: 991px) {
   .aside-secondary-enabled.aside-fixed .wrapper {
      padding-left: 0px;
   }
}

.workspace-aside {
   .aside-primary {
      overflow-x: hidden;
      overflow-y: scroll;
      border-right: none !important;

      /* width */
      &::-webkit-scrollbar {
         width: 7px;
         border-right: none !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
         background: #dde9ff;
         border: none;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
         background: #bebebe;
         border-radius: 7px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
         background: #8f8f8f;
      }
   }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
   .aside {

      // Fixed Aside Mode
      .aside-fixed & {
         position: fixed;
         top: 0;
         bottom: 0;
         left: 0;
         z-index: get($aside-config, base, zindex);
      }

      // Static Aside Mode
      .aside-static & {
         position: relative;
         z-index: 1;
      }

      // Minimized Aside Mode
      .aside-minimize & {
         width: get($aside-config, base, primary-width);
         transition: get($aside-config, base, transition);

         // Aside Primary Panel
         .aside-primary {
            border-color: transparent;
         }
      }

      // Aside Minimize Toggle Icon
      .aside-toggle {
         background-color: $kaporg-dark-green;
         border: $kaporg-dark-green;
         position: absolute;
         right: 0;
         bottom: 40px;
         height: 36px;
         width: 36px;
         margin-right: -(36px/2);
         z-index: get($aside-config, base, zindex) + 1;

         i {
            transition: $transition;
         }

         // Aside Minimize Toggle Icon Active State
         .aside-minimize &,
         &.aside-toggle-active {
            i {
               transition: $transition;
               transform: rotateZ(180deg) #{"/*rtl:ignore*/"};

               background-color: $kaporg-dark-green;
               border: $kaporg-dark-green;

               [direction="rtl"] & {
                  transform: rotateZ(-180deg) #{"/*rtl:ignore*/"};
               }
            }
         }
      }
   }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
   .aside {

      // Aside Primary Panel
      .aside-primary {
         //  background-color: $kaporg-dark-green;
         background-color: #4158cf;
         //  width: get($aside-config, base, primary-width-tablet-and-mobile);

         // Buttons
         .btn.btn-icon.btn-lg {
            width: 40px;
            height: 40px;

            .svg-icon {
               @include svg-icon-size(26px);
            }
         }
      }

      // Aside Secondary Panel
      .aside-secondary {

         // Workspace
         .aside-workspace {
            width: 100%;
            flex-shrink: 0;
            overflow: auto;
         }
      }

      // Aside Minimize Toggle Icon
      .aside-toggle {
         display: none;
      }

      // &.workspace-aside {
      //   left: -465px !important;
      // }
   }

   // Aside Secondary Panel enabled
   .aside-secondary-enabled {
      // @include offcanvas-width(aside,
      //    get($aside-config, base, primary-width-tablet-and-mobile) + get($aside-config, base, secondary-width-tablet-and-mobile));
   }

   // Aside Secondary Panel disabled
   .aside-secondary-disabled {
      // @include offcanvas-width(aside, get($aside-config, base, primary-width-tablet-and-mobile));
   }
}

.nav-footer-help-desk-item {
   width: 100% !important;
   padding: 0 20px !important;

   a {
      padding: 8px 15px;
      min-height: 48px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      transition: 0.2s;
      background-color: transparent;

      .nav-footer-item-wrap-help-desk {
         display: flex;
         align-items: center;

         img {
            max-width: 24px;
            min-width: 24px;
            max-height: 24px;
            width: 24px;
            flex: auto;
            margin-right: 6px;
            margin-left: 6px;
            filter: brightness(0) invert(1);
            opacity: 0.7;
         }

         span {
            display: flex;
            align-items: center;
            flex: auto;
            font-size: 14px;
            line-height: 16px;
            color: #fff;
            opacity: 0.7;
         }
      }

      &:hover,
      &.active {
         background-color: #fff;

         .nav-footer-item-wrap-help-desk {
            img {
               filter: none;
               opacity: 1;
            }

            span {
               color: #4158cf;
               opacity: 1;
            }
         }
      }
   }
}


@media (max-width: 992px) {
   .aside-secondary-enabled.aside-fixed.admin-layout .wrapper {
      padding-left: 0;
   }
}

// Build Aside Menu(see mixin: src/sass/components/mixins/_menu-vertical.scss)
@include menu-ver-build-layout($aside-menu-config);
@include menu-ver-build-theme($aside-menu-config, default);

// Build Aside Offcanvas Panel for mobile mode
@include offcanvas-build(aside, tablet-and-mobile, get($aside-config, offcanvas-mobile));