.mainContainer-ASK {
   margin-bottom: 40px;
   gap: 20px;
   // height: 100vh; /* or any other height */
   // overflow: hidden;
}

.mainContainer-wrapper {
   display: flex;
   justify-content: flex-start;
   position: relative;
   padding: 0;
   gap: 50px;
}

.image-container-ASK {
   flex: 1 1 40%; /* Adjust the percentage as needed */
   max-width: 40%;
   position: relative; /* This makes the absolute positioning relative to this container */
}

.image-container-ASK img {
   position: absolute;
   // top: 100px;
   left: 0;
}

@media (max-width: 768px) {
   .mainContainer-wrapper {
      flex-direction: column;
      padding: 50px 0;
   }

   .image-container-ASK,
   .text-container-ASK {
      max-width: 100%;
      flex: 1 1 100%;
   }

   .image-container-ASK img {
      position: relative;
      width: 100%;
      height: auto;
   }
}
@media (min-width: 768px) {
   .mainContainer-ASK {
      padding: 100px;
      margin-bottom: 50px;
   }
   .text-container-ASK {
      flex: 1 1 60%; /* Adjust the percentage as needed */
      max-width: 60%;
   }
}

@media (min-width: 1024px) {
   .mainContainer-ASK {
      gap: 93px;
   }
}
