.btn.sign-btn {
   background-color: #4158cf;
   border: none;
   color: white;
   border-radius: 8px;
   font-size: 14px;
   font-weight: 500;
   height: 44px;
   /* padding: 4px 16px; */
   width: 112px;
}
.btn.sign-btn:hover {
   color: white;
   background-color: #003daa;
}

.btn.btn-submit {
   background-image: linear-gradient(to left, #036051, #40f372);
   color: white;
   border: none;
}
.password-text {
   color: #5492ff;
}
/* .password-text:hover {
   color: #ff2a00;
} */
.sign-up-btn {
   color: #5492ff;
   font-weight: 700;
   font-size: 14px;
}
.sign-up-btn:hover {
   color: #003daa;
}

.btn.btn-next {
   background-image: linear-gradient(to left, #036051, #40f372);
   border: none;
   color: white;
   position: absolute;
}
.btn.btn-back {
   background-color: var(--kaporg-dark-background);
   color: var(--kaporg-black-text);
}
.test {
   height: 100%;
}

.r-btn {
   color: #777777;
}

.btn.btn-last {
   background-color: #036051;
   color: white;
}
.icon {
   cursor: pointer;
   margin-left: 10px;
}
.input-fields {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.active {
   color: #f1f1f1;
   font-size: 15px;
}

@media (max-width: 767px) {
   .custom-div {
      width: 100%;
   }
   .lottie-div {
      height: auto;
   }
   .lottie-div-registration {
      height: 100%;
   }
}

@media (min-width: 768px) {
   .custom-div {
      width: 510px;
      border-radius: 8px;
      box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
   }
   .lottie-div {
      height: 450px;
   }
   .lottie-div-registration {
      height: 400px;
   }
}

@media (min-width: 1200px) {
   .lottie-div {
      width: 792px;
   }
   .lottie-div-registration {
      width: 100%;
   }
}

.title-paragraph {
   font-size: 22px;
}

.description-paragraph {
   font-size: 28px;
   font-weight: 700;
}

@media (min-width: 1200px) {
   .title-paragraph {
      font-size: 36px;
   }
   .description-paragraph {
      font-size: 44px;
   }
}
.imageContainerMain {
   background-size: cover;
   background-repeat: no-repeat;
   min-height: 100vh;
}

@media (min-width: 1200px) {
   .imageContainerMain {
      background-size: contain;
      background-repeat: unset;
   }
}
