@media (min-width: 992px) {
   .login.login-1 .login-aside {
      width: 100%;
      max-width: 50%;
   }
   /* .login.login-1 .login-form {
      width: 100%;
      max-width: 500px;
   } */
}

@media (max-width: 991.98px) {
   .login.login-1 .login-form {
      width: 100%;
      max-width: 350px;
   }
}

@media (max-width: 575.98px) {
   .login.login-1 .login-form {
      width: 100%;
      max-width: 100%;
   }
}
/*# sourceMappingURL=login-1.css.map */

.layout-content {
   overflow: auto !important;
   height: 100vh;
}

.text-container {
   flex: 1;
   /* padding: 20px; */
   overflow: hidden;
}

.image-container {
   flex: 1;
   display: flex;
   align-items: start;
   overflow: hidden;
   width: 100%;
}

.image-container img {
   max-width: 100%;
   /* max-height: 100%; */
   height: 100%;
   width: 100%;
}
