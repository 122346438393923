.deactivate {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   border: 1px solid #e31a32;
   color: #e31a32;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.deactivate:hover,
.deactivate:focus,
.deactivate:active {
   border-color: #e31a32 !important;
   background-color: #f5f5f5 !important;
   color: #e31a32 !important;
}

.creation-summary-grid {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 24px;
}

.upgrade {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   background-color: #ee903a;
   border: none;
   color: #fff;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
   white-space: normal;
}

.upgrade:hover,
.upgrade:focus,
.upgrade:active {
   border-color: #be732e !important;
   background-color: #be732e !important;
   color: #fff !important;
}

.edit-outlined {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid #4158cf;
   color: #4158cf;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.edit-outlined:hover,
.edit-outlined:focus,
.edit-outlined:active {
   border-color: #4158cf !important;
   background-color: #f1f6ff !important;
   color: #4158cf !important;
}

.select-yearly-plan {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid var(--Secondary-Green-Green, #20BAB7);
   color: #20BAB7;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.select-yearly-plan:hover,
.select-yearly-plan:focus,
.select-yearly-plan:active {
   border: 1px solid var(--Secondary-Green-Green, #20BAB7) !important;
   background-color: #fff !important;
   color: #20BAB7 !important;
}

.select-monthly-plan {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid var(--Secondary-Green-Green, #5492FF);
   color: #5492FF;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.select-monthly-plan:hover,
.select-monthly-plan:focus,
.select-monthly-plan:active {
   border: 1px solid var(--Secondary-Green-Green, #5492FF) !important;
   background-color: #fff !important;
   color: #5492FF !important;
}

.paymentWallet {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 100px;
}

.plan {
   width: 407px;
}

.profile-grid {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 16px;
}

@media (max-width: 768px) {
   .paymentWallet {
      grid-template-columns: 1fr;
      gap: 40px;
   }

   .plan {
      width: 100%;
   }

   .profile-grid {
      grid-template-columns: 1fr;
      gap: 16px;
   }

   .creation-summary-grid {
      grid-template-columns: 1fr;
   }
}

@media (min-width: 768px) {
   .noSectionSpace {
      margin-top: 35px;
   }
}

.accountManagementContainer {
   border: 1.5px solid #dfe6ff;
   border-radius: 8px;
   padding: 32px 24px;
   background-color: #fff;
}

.title-heading {
   color: #003daa;
   font-weight: 500;
   font-family: poppins;
   font-size: 18px;
   margin-bottom: 8px;
}

.subtitle-action {
   color: #e31a32;
   font-family: Poppins;
   font-size: 16px;
}

.action-desc {
   color: #3a3737;
   font-family: Lato;
   font-size: 16px;
}

.horizontal-line {
   display: flex;
   width: 100%;
   background-color: #dfe6ff;
   height: 1px;
}

.accountDeletedDeactivated {
   margin: 0 !important;
   padding: 30px 20px;
   height: auto;
   background-color: #fff;
}

.labelStatus {
   color: #4158cf;
   font-weight: 600;
   font-size: 26px;
   text-align: center;
   margin: 0 0 8px;
   font-family: Poppins;
}

.lastParagraphSection {
   align-self: stretch;
   gap: 30px;
   text-align: center;
}

.paragraph-text {
   color: #555;
   font-size: 16px;
   margin: 0;
}

.last-paragraph-text {
   color: #3a3737;
   font-size: 16px;
   font-weight: 600;
}

.accountSummaryContainer {
   gap: 40px;
   background-color: #f5f9fc;
}

.mainSection {
   padding: 40px 32px;
   margin: 20px;
   background-color: #fff;
   box-shadow: 0px 2px 4px 0px rgba(19, 30, 69, 0.1);
   border-radius: 16px;
}

.labelStatusMain {
   color: #003daa;
   font-weight: 600;
   font-family: poppins;
   font-size: 18px;
   margin-bottom: 8px;
}

.summary-main {
   padding: 16px 32px;
   border-radius: 8px;
   border: 1px solid #ee903a;
   background-color: #fff8e8;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   gap: 8px;
}

.labelTitle {
   color: #ee903a;
   font-weight: 700;
   font-family: Lato;
   font-size: 16px;
   margin: 0;
   display: flex;
   align-items: center;
}

.usageStatisticsContainer {
   border: 1.5px solid #dfe6ff;
   border-radius: 8px;
   padding: 32px 24px;
   background-color: #fbfdff;
}

.amountContainer {
   gap: 20px;
   background-color: #fff;
   display: flex;
}

.creationSummaryContainer {
   padding: 20px;
   border: 1px solid #dfe6ff;
   border-radius: 10px;
   background-color: #fbfdff;
}

.countNumber {
   font-size: 40px;
   font-weight: 500;
   font-family: Poppins;
   color: #003daa;
}

.totalCountNumber {
   font-size: 40px;
   font-weight: 500;
   font-family: Poppins;
   color: #b3bcec;
}

.labelForProperty {
   margin: 0;
   font-size: 18px;
   font-weight: 600;
   font-family: Lato;
   color: #555555;
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 5px;
}

.additionalText {
   font-weight: 400;
   font-size: 16px;
   color: #777777;
}
.password-section {
   display: flex;

   .eyeButton {
      cursor: pointer;
      margin-left: -25px;
      display: block;
      z-index: 10;
   }
}

.view-billing-portal {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid var(--Secondary-Green-Green, #4158CF);
   color: #4158CF;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.view-billing-portal:hover,
.view-billing-portal:focus,
.view-billing-portal:active {
   border: 1px solid var(--Secondary-Green-Green, #4158CF) !important;
   background-color: #fff !important;
   color: #4158CF !important;
}

.checkout-button {
   display: flex;
   width: 140px;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: var(--Corners-corner-medium, 8px);
   border-radius: 8px;
   background-color: #4158CF;
   color: var(--character-primary-inverse, #FFF);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
}

.checkout-button:hover,
.checkout-button:focus,
.checkout-button:active {
   background-color: #4158CF !important;
   color: #fff !important;
}

.checkout-button:disabled {
   background: var(--Neutral-3, #F5F5F5);
   color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25));
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
}

.checkout-button:disabled:hover,
.checkout-button:disabled:focus,
.checkout-button:disabled:active {
   background: var(--Neutral-3, #F5F5F5) !important;
   color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25)) !important;
}


.plan-selected-yearly {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #20BAB7;
   color: #fff;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.plan-selected-yearly:hover,
.plan-selected-yearly:focus,
.plan-selected-yearly:active {
   background-color: #20BAB7 !important;
   color: #fff !important;
}

.plan-selected-monthly {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #5492FF;
   color: #fff;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.plan-selected-monthly:hover,
.plan-selected-monthly:focus,
.plan-selected-monthly:active {
   background-color: #5492FF !important;
   color: #fff !important;
}

.full-page-background {
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: relative;
}

.logo-link {
   position: absolute;
   top: 20px;
   left: 20px;
}

.logo {
   max-height: 70px;
}

.centered-box {
   text-align: center;
   display: flex;
   padding: 80px 40px;
   flex-direction: column;
   align-items: center;
   gap: 24px;
   border-radius: 8px;
   background: var(--Neutrals-White, #FFF);
   box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
   width: 40%;

   h1 {
      color: var(--Primary-Main-Blue, #4158CF);
      text-align: center;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      margin: 0
   }

   h2 {
      color: var(--system-red-error, #E31A32);
      text-align: center;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      margin: 0;
   }

   p {
      color: var(--Neutrals-Gray, #555);
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.32px;
      margin: 0;
   }

   span {
      color: var(--Neutrals-Black, #3A3737);
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.32px;
      margin: 0;
   }

   .back-to-payment-info-button {
      display: flex;
      width: 140px;
      height: 44px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: var(--Corners-corner-medium, 8px);
      border-radius: 8px;
      background-color: #4158CF;

      .button-info {
         display: flex;
         align-items: center;
         gap: 8px;

         .back-to-payment-info-text {
            color: #FFF;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
         }
      }
   }

   .back-to-payment-info-button:hover,
   .back-to-payment-info-button:focus,
   .back-to-payment-info-button:active {
      background-color: #4158CF !important;

      .button-info {
         .back-to-payment-info-text {
            color: #fff !important;
         }
      }
   }
}

@media (max-width: 900px) {
   .centered-box {
      width: 100%;
      padding: 40px 20px;
   }
}


.plan-selected-yearly {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #20BAB7;
   color: #fff;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.plan-selected-yearly:hover,
.plan-selected-yearly:focus,
.plan-selected-yearly:active {
   background-color: #20BAB7 !important;
   color: #fff !important;
}

.plan-selected-monthly {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #EE903A;
   color: #fff;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.plan-selected-monthly:hover,
.plan-selected-monthly:focus,
.plan-selected-monthly:active {
   background-color: #EE903A !important;
   color: #fff !important;
}

.plan-selected-onetime {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #5492FF;
   color: #fff;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.plan-selected-onetime:hover,
.plan-selected-onetime:focus,
.plan-selected-onetime:active {
   background-color: #5492FF !important;
   color: #fff !important;
}

.select-yearly-plan {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid var(--Secondary-Green-Green, #20BAB7);
   color: #20BAB7;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.select-yearly-plan:hover,
.select-yearly-plan:focus,
.select-yearly-plan:active {
   border: 1px solid var(--Secondary-Green-Green, #20BAB7) !important;
   background-color: #fff !important;
   color: #20BAB7 !important;
}

.select-monthly-plan {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid #EE903A;
   color: #EE903A;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.select-monthly-plan:hover,
.select-monthly-plan:focus,
.select-monthly-plan:active {
   border: 1px solid #EE903A !important;
   background-color: #fff !important;
   color: #EE903A !important;
}

.select-onetime-plan {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background-color: #fff;
   border: 1px solid var(--Secondary-Green-Green, #5492FF);
   color: #5492FF;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
}

.select-onetime-plan:hover,
.select-onetime-plan:focus,
.select-onetime-plan:active {
   border: 1px solid var(--Secondary-Green-Green, #5492FF) !important;
   background-color: #fff !important;
   color: #5492FF !important;
}