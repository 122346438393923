.aside-menu-wrapper {
   scroll-behavior: smooth;
  //  max-height: 95vh !important;
   overflow: auto;

   &::-webkit-scrollbar {
      width: 8px;
   }

   /* Track */
   &::-webkit-scrollbar-track {
      border-radius: 10px;
   }

   /* Handle */
   &::-webkit-scrollbar-thumb {
      background: lightgray;
      border-radius: 10px;
   }

   /* Handle on hover */
   &::-webkit-scrollbar-thumb:hover {
      background: rgb(188, 188, 188);
   }
}

.organization-search-bar {
   padding: 0 12px;

   input {
      height: 41px;
      width: 100%;
      left: 8px;
      top: 68.5px;
      background: var(--kaporg-table-background);
      border-radius: 6px;
      outline: none;
      border: none;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #575757;
      padding: 0 10px;

      &::placeholder {
         color: #aed2b8;
         text-align: center;
      }
   }
}

.no-organization-message {
   color: var(--kaporg-black-text);
   margin: 10px;

}

.aside-footer {
   padding-left: 2.5rem;
   padding-top: 8.5rem;
}

.menu-link {
   display: flex;
   gap: 16px !important;
}

.admin-menu,
.menu-nav {
   display: flex;
   flex-direction: column;
   gap: 7px;
}

.log-out-aside {
   display: flex;
   gap: 16px;
   align-items: center;
   padding-left: 7px;
   cursor: pointer;

   div {
      color: #DFE6FF;
      font-size: 15px;
      font-weight: 500;
      line-height: 16px;
      font-family: Poppins;
   }
}

.menu-item {

  .active {
    display: none;
  }

  .inactive {
    display: block;
  }

  &.menu-item-active {
    .active {
      display: block;
    }

    .inactive {
      display: none;
    }
  }
}