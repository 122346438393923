@media screen and (min-width: 768px) {
   .aboutKapSurveySectionStyle {
      margin-bottom: 130px;
   }
}
@media screen and (max-width: 768px) {
   .aboutKapSurveySectionStyle {
      padding: 0 16px;
      margin-bottom: 60px;
   }
}
.aboutKapSurveySectionStyle {
   /* margin-top: 140px; */
   margin-top: 40px;
   /* padding: 0 6rem; */

   .content {
      text-align: center;
      padding: 0 13rem;
      margin-bottom: 40px;
   }
   .h1 {
      text-align: center;
      color: #4158cf;
      font-family: "Poppins";
      font-weight: 700;
      font-size: 38px;
      line-height: 70px;
   }
   .p {
      text-align: center;
      margin-top: 32px;
      font-family: "Lato";
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #5a5a5c;
   }
   .firstSection {
      display: flex;
      justify-content: center;
      gap: 20px;
   }
   /* @media screen and (max-width: 1008px) {
     .firstSection {
       display: block;
     }
   } */
   .card {
      padding: 46px 35px 0 35px;
      border-radius: 32px;
      background-color: #f58f45;
      border: #f58f45;
      color: white;
      width: 400px;
   }
   .heading3Style {
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 700;
      line-height: 34.56px;
      color: white;
   }
   .contentInnerCard {
      display: flex;
      align-items: baseline;
   }
   .dataCollectionImg {
      bottom: 0;
      border-radius: 0 0 32px 0;
      padding: 0 29px;
      margin-top: 23px;
   }
   .buttonsDiv {
      display: flex;
      justify-content: center;
      gap: 10px;
   }
   .exploreAllbutton {
      background-color: #003daa;
      border: #ffd7bb;
      border-radius: 4px;
      padding: 12px 24px 12px 24px;
      gap: 10px;
      color: #ffffff;
      text-align: center;
      text-decoration: none;
      font-family: "Source Sans 3";
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
   }
   .seeOurPricingButton {
      background-color: #eef2ff;
      border: #d9e3ff;
      border-radius: 4px;
      padding: 12px 24px 12px 24px;
      gap: 10px;
      color: #5770cb;
      text-align: center;
      text-decoration: none;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
   }
   .secondCard {
      width: 100%;
      border-radius: 32px;
      background-color: #4158cf;
      border: #4158cf;
      color: white;
   }
   .secondInnerCard {
      display: flex;
      /* justify-content: space-around; */
      /* gap: 10rem; */
      margin-top: 9px;
   }
   .secondInnerCardContent {
      display: flex;
      align-items: center;
   }
   /* .taskManagementImg {
      margin-left: -107px;
      width: 600px;
      margin-top: -10px;
   } */

   .dataAnalysisImg {
      width: auto;
      border-radius: 32px;
      height: 350px;
   }

   .secondSection {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      gap: 20px;
      flex-wrap: wrap;
   }
   .secondSectionCard {
      border-radius: 32px;
      background-color: #20bab7;
      border: #5770cb;
      color: white;
      /* height: 100%; */
      min-height: 464px;
      flex: 0 0 calc(60% - 20px);
      width: calc(60% - 20px);
   }

   .secondSectionInnerCard {
      display: flex;
      flex-direction: column;
      padding: 0px 0 0 35px;
      width: 400px;
   }

   @media only screen and (max-width: 600px) {
      .secondSectionInnerCard {
         width: auto; /* Set width to auto for smaller devices */
         padding-left: 20px; /* Adjust padding as needed */
         padding-right: 20px; /* Adjust padding as needed */
      }
      .beneficieriesImg {
         width: 100%;
         height: fit-content;
      }
   }

   .secondSectionInnerCardContent {
      display: flex;
      align-items: center;
   }
   @media only screen and (min-width: 768px) {
      .beneficieriesImg {
         width: 400px;
         position: absolute;
         bottom: 0;
         right: 0;
         /* height: fit-content; */
      }
   }

   .secondSectionSecondCard {
      border-radius: 32px;
      background-color: #4158cf;
      border: #f58f45;
      color: white;
      flex: 0 0 40%;
      width: 40%;
      height: 100%;
   }
   /* .secondSectionSecondInnerCard {
     padding: 46px 35px 0 35px;
   } */
   .secondSectionSecondInnerCardContent {
      display: flex;
      align-items: baseline;
   }

   @media screen and (max-width: 1344px) {
      .firstSection {
         display: block;
      }
      .card {
         width: 100%;
      }
      .card {
         margin-bottom: 2rem;
      }
      .secondSectionCard {
         margin-bottom: 2rem;
      }
      .secondSection {
         display: block;
      }
      .secondSectionSecondCard {
         width: 100% !important;
      }
      .secondSectionCard {
         width: 100% !important;
      }
      .secondCard {
         width: 100% !important;
      }
   }
}
.contentInnerCard p {
   font-family: "Lato" !important;
   font-size: 16px !important;
   font-weight: 400;
   line-height: 24px;
}
@media screen and (max-width: 430px) {
   .aboutKapSurveySectionStyle {
      /* padding: 0 2rem; */
      .h1 {
         font-size: 24px;
         line-height: 30px;
      }
      .buttonsDiv {
         display: flex;
         flex-direction: column;
      }
   }

   .secondInnerCardContent > p {
      font-size: 17px !important;
   }
}

@media screen and (max-width: 992px) {
   .secondInnerCard {
      display: block !important;
   }
   .content {
      padding: 0 !important;
   }
   .card {
      width: 100% !important;
   }
   .taskManagementImg {
      width: 100% !important;
      margin-left: 0 !important;
   }
   .dataCollectionImg {
      width: 100% !important;
   }
   .secondCard {
      width: 100% !important;
   }
   .secondSectionCard {
      width: 100% !important;
   }
   .beneficieriesImg {
      padding: 0% !important;
      margin-top: 0 !important;
   }

   .secondSectionSecondCard {
      width: 100% !important;
   }
   /* .secondSectionSecondInnerCard {
     padding: 46px 35px 46px 35px;
   } */
   .dataAnalysisImg {
      width: 100%;
   }
}

.sectionPadding {
   padding: 20px;
}
.sectionPadding2 {
   padding: 20px;
}

.sectionPaddingInnerCard {
   padding: 20px;
}

.secondSectionCardCheckImg {
   display: flex;
   align-items: end;
   padding: 20px;
}

@media screen and (min-width: 768px) {
   .sectionPadding {
      padding: 0px 0 0px 57px;
   }
   .sectionPadding2 {
      padding: 48px 0 35px 57px;
   }
   .sectionPaddingInnerCard {
      padding: 48px 0 0px 57px;
   }
   .secondSectionCardCheckImg {
      padding: 20px 40px 25px 40px;
   }
}
