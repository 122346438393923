.dropzone-wrapper {
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
   border-width: 2;
   border-radius: 2;
   border-color: #eeeeee;
   border-style: dashed;
   background-color: #fafafa;
   color: #bdbdbd;
   outline: none;
   transition: border 0.24s ease-in-out;
   &.drag-accepted {
      border-color: #00e676;
   }
   &.drag-rejected {
      border-color: #ff1744;
   }
   &.drag-focused {
      border-color: #2196f3;
   }
}
